import React from 'react';
import { makeRangeDatepickerFilter } from '@crpt-ui/datatable/lib/Filters/RangeDatepickerFilter';
import { makeSelectFilter } from '@crpt-ui/datatable/lib/Filters/SelectFilter';
import { makeCheckboxGroupFilter } from '@crpt-ui/datatable/lib/Filters/CheckboxGroupFilter';
import { Translate } from '../../components/translate';
import { PRODUCT_GROUPS, PRODUCT_GROUPS_LIST } from '../../constants';
import { ProductGroupCell } from '../../components/table/cells/productGroupCell';

export const MARKING_OPTIONS_NAMES = {
  PRINTED: 'КМ был напечатан',
  VERIFIED: 'Нанесение КМ подтверждено',
  USED_FOR_PRODUCTION: 'КМ использован на производстве',
};

export const IS_MARKING_OPTIONS = [
  { value: 'PRINTED', label: Translate(MARKING_OPTIONS_NAMES.PRINTED) },
  { value: 'VERIFIED', label: Translate(MARKING_OPTIONS_NAMES.VERIFIED) },
  { value: 'USED_FOR_PRODUCTION', label: Translate(MARKING_OPTIONS_NAMES.USED_FOR_PRODUCTION) },
];

export const MARKING_STATUSES = {
  DRAFT: 'Черновик',
  READY_TO_SEND: 'Готов к отправке',
  SENT: 'Отправлен',
  PENDING: 'В процессе',
  REJECTED: 'Отклонен',
};

export const DROPOUT_STATUSES = {
  DRAFT: 'Черновик',
  SENT: 'Обработан успешно',
  PENDING: 'В процессе',
  READY_TO_SEND: 'Готов к отправке',
  REJECTED: 'Отклонен',
  WAITING_FOR_RESULT: 'В процессе',
};

export const AGGREGATION_STATUSES = {
  DRAFT: 'Черновик',
  READY_TO_SEND: 'Готов к отправке',
  SENT: 'Обработан успешно',
  PENDING: 'В процессе',
  REJECTED: 'Отклонен',
  WAITING_FOR_RESULT: 'В процессе',
};

export const MARKING_STATUSES_FILTER = [
  { value: 'DRAFT', label: MARKING_STATUSES.DRAFT },
  { value: 'READY_TO_SEND', label: MARKING_STATUSES.READY_TO_SEND },
  { value: 'SENT', label: MARKING_STATUSES.SENT },
  { value: 'REJECTED', label: MARKING_STATUSES.REJECTED },
];

export const PHARMA_ORDER_TYPES = [
  { value: 1, label: Translate('Собственное производство') },
  { value: 2, label: Translate('Контрактное производство') },
];

export const ORDER_TYPE_NAMES = {
  1: 'Собственное производство',
  2: 'Контрактное производство',
};

export const USED_IN_PRODUCTION_OPTIONS = [
  { value: false, label: Translate('Нет') },
  { value: true, label: Translate('Да') },
];

export const codeHeaders = [
  {
    accessor: (row) => row.code,
    id: 'code',
    Header: Translate('Коды маркировки'),
  },
];

export const headers = [
  {
    accessor: (row) => row.productGroupType,
    Cell: ({ cell }) => (<ProductGroupCell pg={cell.value} />),
    id: 'productGroupType',
    Filter: makeSelectFilter({ options: PRODUCT_GROUPS_LIST }),
    Header: Translate('Товарная группа'),
  },
  {
    accessor: (row) => row.codesCount,
    id: 'codesCount',
    Header: Translate('Кол-во КМ'),
  },
  {
    accessor: (row) => row.creationDate,
    Header: Translate('Дата создания'),
    id: 'creationDate',
    Filter: makeRangeDatepickerFilter(),
  },
  {
    accessor: (row) => row.status,
    Cell: ({ cell }) => (
      <>
        {' '}
        {MARKING_STATUSES[cell.value]}
        {' '}
      </>
    ),
    id: 'status',
    Filter: makeCheckboxGroupFilter({ options: MARKING_STATUSES_FILTER }),
    Header: Translate('Статус'),
  },
];

export const VIEW_TYPES_WITH_PRODUCTION = [
  PRODUCT_GROUPS.pharma, PRODUCT_GROUPS.tobacco, PRODUCT_GROUPS.alcohol,
  PRODUCT_GROUPS.beer, PRODUCT_GROUPS.water, PRODUCT_GROUPS.antiseptic,
];

export const currentDate = new Date();
export const tomorrow = new Date(new Date().setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 24);
