import React from 'react';
import { makeRangeDatepickerFilter } from '@crpt-ui/datatable/lib/Filters/RangeDatepickerFilter';
import { makeCheckboxGroupFilter } from '@crpt-ui/datatable/lib/Filters/CheckboxGroupFilter';
import { DefaultFilter } from '@crpt-ui/datatable/lib/Filters/DefaultFilter';
import { isNil } from 'lodash';
import { Translate } from '../../components/translate';
import { PRODUCT_GROUPS } from '../../constants';
import { ProductGroupCell } from '../../components/table/cells/productGroupCell';
import { LinkCell } from '../../components/table/cells/LinkCell';
import { routes } from '../../routes';
import { getPgListByCountry } from '../../utils/utils';
import ViewProductStatus from './view/viewProductStatus';

export const RELEASE_METHOD_TYPES_NAMES = {
  PRODUCTION: 'Производство в стране',
  IMPORT: 'Ввезен в страну (импорт)',
  REMARK: 'Перемаркировка',
  COMMISSION: 'Принят на комиссию от физического лица',
  REMAINS: 'Остатки',
};

export const CREATE_METHOD_TYPES_NAMES = {
  SELF_MADE: 'Самостоятельно',
  CEM: 'ЦЭМ',
  CM: 'Контрактное производство',
  CL: 'Логистический склад',
  CA: 'Комиссионная площадка',
};

export const RELEASE_METHOD_TYPES = [
  {
    value: 'PRODUCTION',
    label: Translate('Производство в стране'),
    countries: ['KZ', 'UZ', 'KG', 'AM'],
    productGroups: {
      KZ: [
        PRODUCT_GROUPS.shoes,
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.pharma,
        PRODUCT_GROUPS.milk,
        PRODUCT_GROUPS.lp,
        PRODUCT_GROUPS.water,
      ],
      UZ: [
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.beer,
        PRODUCT_GROUPS.water,
        PRODUCT_GROUPS.pharma,
        PRODUCT_GROUPS.appliances,
        PRODUCT_GROUPS.antiseptic,
      ],
      KG: [PRODUCT_GROUPS.tobacco, PRODUCT_GROUPS.alcohol],
      AM: [PRODUCT_GROUPS.lp, PRODUCT_GROUPS.shoes],
    },
  },
  {
    value: 'IMPORT',
    label: Translate('Ввезен в страну (импорт)'),
    countries: ['KZ', 'UZ', 'KG', 'AM'],
    productGroups: {
      KZ: [
        PRODUCT_GROUPS.shoes,
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.pharma,
        PRODUCT_GROUPS.milk,
        PRODUCT_GROUPS.lp,
        PRODUCT_GROUPS.water,
      ],
      UZ: [
        PRODUCT_GROUPS.alcohol,
        PRODUCT_GROUPS.beer,
        PRODUCT_GROUPS.water,
        PRODUCT_GROUPS.pharma,
        PRODUCT_GROUPS.appliances,
        PRODUCT_GROUPS.antiseptic,
      ],
      KG: [PRODUCT_GROUPS.tobacco, PRODUCT_GROUPS.alcohol],
      AM: [PRODUCT_GROUPS.lp, PRODUCT_GROUPS.shoes],
    },
  },
  {
    value: 'REMARK',
    label: Translate('Перемаркировка'),
    countries: ['KZ'],
    productGroups: {
      KZ: [PRODUCT_GROUPS.shoes, PRODUCT_GROUPS.lp],
    },
  },
  {
    value: 'COMMISSION',
    label: Translate('Принят на комиссию от физического лица'),
    countries: ['KZ'],
    productGroups: {
      KZ: [PRODUCT_GROUPS.shoes, PRODUCT_GROUPS.lp],
    },
  },
];

export const CREATE_METHOD_TYPES = [
  { value: 'SELF_MADE', label: Translate('Самостоятельно') },
];

export const CREATE_METHOD_TYPES_WITH_SERVICE = [
  { value: 'CEM', label: Translate('ЦЭМ') },
  { value: 'CM', label: Translate('Контрактное производство') },
  { value: 'CL', label: Translate('Логистический склад') },
  { value: 'CA', label: Translate('Комиссионная площадка') },
];

export const COUNTRIES = [
  { value: 'AM', label: 'Армения' },
];

export const EMISSION_TYPES = [
  { value: 'OPERATOR', label: Translate('Автоматически') },
  { value: 'SELF_MADE', label: Translate('Пользователем') },
];

export const FILE_TYPES = [
  { value: 'PDF', label: 'PDF' },
  { value: 'CSV', label: 'CSV' },
];

export const FILE_TEMPLATES = [
  { value: '1', label: Translate('В одну колонку') },
  { value: '2', label: Translate('На весь лист') },
];

export const STICKER_TEMPLATES = [
  { value: 'small', label: Translate('Простая белая') },
  { value: 'wideImport', label: Translate('Широкая этикетка (импорт)') },
  { value: 'wideRemains', label: Translate('Широкая этикетка (маркировка остатков)') },
];

export const ORDER_STATUSES = {
  CREATED: 'Создан',
  PENDING: 'В ожидании',
  READY: 'Готов',
  REJECTED: 'Отклонен',
  CLOSED: 'Закрыт',
  EXPIRED: 'Аннулирован',
};

export const PRODUCT_STATUSES = {
  ACTIVE: Translate('Активный'),
  PENDING: Translate('Создан'),
  REJECTED: Translate('Отклонен'),
  CLOSED: Translate('Закрыт'),
  EXPIRED: Translate('Аннулирован'),
  EXHAUSTED: Translate('Исчерпан (не содержит кодов)'),
};

export const ORDER_STATUSES_FILTER = [
  { value: 'CREATED', label: Translate(ORDER_STATUSES.CREATED) },
  { value: 'PENDING', label: Translate(ORDER_STATUSES.PENDING) },
  { value: 'READY', label: Translate(ORDER_STATUSES.READY) },
  { value: 'REJECTED', label: Translate(ORDER_STATUSES.REJECTED) },
  { value: 'CLOSED', label: Translate(ORDER_STATUSES.CLOSED) },
  { value: 'EXPIRED', label: Translate(ORDER_STATUSES.EXPIRED) },
];

export const headers = [
  {
    accessor: (row) => row.orderId,
    Cell: ({ cell }) => (<LinkCell text={cell.value} link={routes.orders(cell.value)} />),
    id: 'orderId',
    Header: Translate('Идентификатор заказа'),
    Filter: DefaultFilter,
  },
  {
    accessor: (row) => row.pg,
    Cell: ({ cell }) => (<ProductGroupCell pg={cell.value} />),
    id: 'pg',
    Filter: makeCheckboxGroupFilter({ options: getPgListByCountry(window.env.COUNTRY_CODE) }),
    Header: Translate('Товарная группа'),
    width: 210,
  },
  {
    accessor: (row) => row.createDate,
    Header: Translate('Дата создания'),
    id: 'createDate',
    Filter: makeRangeDatepickerFilter(),
  },
  {
    accessor: (row) => row.productCount,
    id: 'productCount',
    Header: Translate('Количество товаров'),
  },
  {
    accessor: (row) => row.codesCount,
    id: 'codesCount',
    Header: Translate('Количество кодов'),
  },
  {
    accessor: (row) => row.orderStatus,
    Cell: ({ cell }) => (
      <>
        {' '}
        {Translate(ORDER_STATUSES[cell.value])}
        {' '}
      </>
    ),
    id: 'orderStatus',
    Filter: makeCheckboxGroupFilter({ options: ORDER_STATUSES_FILTER }),
    Header: Translate('Статус'),
  },
];

export const rateTypeOptions = [
  {
    value: 0,
    label: Translate('Нулевой'),
  },
  {
    value: 1,
    label: Translate('Стандартный'),
  },
];

export const productHeaders = [
  {
    accessor: (row) => row.gtin,
    id: 'gtin',
    Header: Translate('Код товара'),
  },
  {
    accessor: (row) => Translate(row.cisType),
    id: 'cisType',
    Header: Translate('Тип кода маркировки'),
  },
  {
    accessor: (row) => row.availableCodes,
    id: 'availableCodes',
    Header: Translate('Всего кодов'),
  },
  {
    accessor: (row) => row.totalPassed,
    id: 'totalPassed',
    Header: Translate('Получено кодов'),
  },
  {
    accessor: (row) => row.leftInBuffer,
    id: 'leftInBuffer',
    Header: Translate('Остаток кодов'),
  },
  {
    accessor: (row) => {
      if (isNil(row.rateType)) return '—';
      return Translate(row.rateType ? 'Стандартный' : 'Нулевой');
    },
    id: 'rateType',
    Header: Translate('Тариф'),
  },
  {
    accessor: (row) => row.bufferStatus,
    id: 'bufferStatus',
    Cell: ({ cell, row }) => (
      <ViewProductStatus
        cell={cell}
        row={row}
      />
    ),
    Header: Translate('Статус'),
  },
];

export const availableCodesHeaders = [
  {
    accessor: (row) => row.gtin,
    id: 'gtin',
    Header: Translate('Код товара'),
  },
  {
    accessor: (row) => row.packId,
    id: 'packId',
    Header: Translate('Идентификатор пакета (партии)'),
  },
  {
    accessor: (row) => row.packDateTime,
    id: 'packDateTime',
    Header: Translate('Дата получения'),
  },
  {
    accessor: (row) => row.count,
    id: 'count',
    Header: Translate('Получено кодов'),
  },
];

export const formatQuantityCodes = (quantityCodes, setQuantityCodes, fileType) => (v) => {
  if (isNil(v) || v === '') {
    setQuantityCodes('');
    return '';
  }
  const maxCodes = fileType === 'PDF' ? 500 : 30000;
  const val = parseInt(v, 10);
  const formattedValue = val <= maxCodes ? val : quantityCodes;
  setQuantityCodes(formattedValue);
  return formattedValue;
};

export const DEFAULT_PRODUCT_LINE = {
  file: null, serialNumbers: [], gtin: '', cisType: '', quantity: '', serialNumberType: '',
};

export const DISABLED_STATUSES = ['EXHAUSTED', 'REJECTED', 'CLOSED', 'EXPIRED'];
export const CLOSE_BUFFER_STATUSES = ['EXHAUSTED', 'ACTIVE'];
